import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ShipperUserShipperUserPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserReleaseNotePermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUserReleaseNoteViewPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.SHIPPER_USER]: ShipperUserShipperUserPermissionName,
  [ObjectType.RELEASE_NOTE]: ShipperUserReleaseNotePermissionName,
  [ObjectType.USER_RELEASE_NOTE_VIEW]:
    ShipperUserUserReleaseNoteViewPermissionName,
};
