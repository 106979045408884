import {
  addressFields,
  phoneFields,
  recordActionFields,
  recordFields,
} from '@xbcb/shared-queries';
import {
  commonFacilityFragment,
  addressListFragment,
  supplierFragment,
  importerFragment,
  pointOfContactFields,
} from '@xbcb/party-queries';

export const getCustomsDeclarationFragment = (
  customsDeclarationFields: string,
  type: string,
  declarationSpecificFields = '',
): string => {
  return `fragment ${customsDeclarationFields} on ${type} {
    identification
    ...recordFields
    ${declarationSpecificFields}
    operator {
      id
    }
    agent {
      id
      name
      email
      addresses {
        mailing {
          ...addressFields
        }
        physical {
          ...addressFields
        }
      }
      pointOfContact {
        ...pointOfContactFields
      }
      tags {
        key
        value
      }
    }
    badges
    documents {
      id
      version
      documentTags
      tags {
        key
        value
      }
    }
    goodsShipment {
      importer {
        id
        name
        ... on NlIor{
          nlVatNumber: vatNumber
          nlEori: eori
          businessStructure
          duns
          email
        }
        ...importerFields
      }
      buyer {
			  ...commonFacilityFields
		  }
      goodsItem {
        seller {
          ...supplierFields
        }
		  }
      consignment {
        containerized
        value
        borderTransportationMean {
          name
          identificationType
          arrival
          departure
          journey
          arrivalLocation {
            identification
            name
            }
          departureLocation {
            identification
            name
          }
        }
        transportEquipment {
          identification
          fullness
        }
        transportContractDocument {
          type
          identification
        }
      }
      exportCountry {
        country
      }
      importCountry {
        country
      }
    }
    tasks {
      id
      version
      definition {
        workOrderTaskType
      }
      status
    }
    milestones {
      id
      version
      name
      occurenceTime
    }
    group {
      documents {
        documentTags
      }
      customsBroker {
        tags {
          key
          value
        }
      }
      forwarders {
        forwarder {
          id
          version
        }
      }
      shipment {
        id
        version
        clientReferences {
          bookingId
        }
      }
      invoices {
        id
        number
        deleted {
          time
        }
        status
      }
      shipper {
        id
        version
      }
    }
    stateMachine {
      id
      version
    }
  }`;
};

const customsDeclarationFragment = getCustomsDeclarationFragment(
  'CustomsDeclarationFields',
  'CustomsDeclaration',
);

export const customsDeclarationFragments = `
  ${customsDeclarationFragment}
  ${commonFacilityFragment}
  ${addressListFragment}
  ${supplierFragment}
  ${importerFragment}
  ${addressFields}
  ${phoneFields}
  ${pointOfContactFields}
  ${recordFields}
  ${recordActionFields}
`;
