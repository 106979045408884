import { EsDataType } from '../../enums';
import { createTableConfig } from '../createTableConfig';

export const releaseNote = createTableConfig({
  columns: {
    title: {
      default: true,
      name: 'Title',
      type: EsDataType.TEXT,
    },
    description: {
      default: true,
      name: 'Description',
      type: EsDataType.TEXT,
    },

    targetCountries: {
      default: true,
      name: 'Target Countries',
      type: EsDataType.TEXT,
    },
  },
});
