import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserOperatorUserPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserAssignmentTeamPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserBusinessSupportTeamPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserMemoPermissionName = {
  DELETE: 'DELETE',
  READ: 'READ',
};

export const OperatorUserReleaseNotePermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserUserReleaseNoteViewPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.OPERATOR_USER]: OperatorUserOperatorUserPermissionName,
  [ObjectType.ASSIGNMENT_TEAM]: OperatorUserAssignmentTeamPermissionName,
  [ObjectType.BUSINESS_SUPPORT_TEAM]:
    OperatorUserBusinessSupportTeamPermissionName,
  [ObjectType.MEMO]: OperatorUserMemoPermissionName,
  [ObjectType.RELEASE_NOTE]: OperatorUserReleaseNotePermissionName,
  [ObjectType.USER_RELEASE_NOTE_VIEW]:
    OperatorUserUserReleaseNoteViewPermissionName,
};
