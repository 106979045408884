import { EsDataType } from '../../enums';
import { createTableConfig } from '../createTableConfig';

export const userReleaseNoteView = createTableConfig({
  columns: {
    userId: {
      default: true,
      name: 'User ID',
      type: EsDataType.TEXT,
    },
    releaseNoteId: {
      default: true,
      name: 'Release Note ID',
      type: EsDataType.TEXT,
    },

    rating: {
      default: true,
      name: 'Rating',
      type: EsDataType.TEXT,
    },
  },
});
