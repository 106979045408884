import { EuCountryCodes } from '@xbcb/work-order-types';
import { getRecordType } from '@xbcb/core';
import { RecordType } from '@xbcb/shared-types';
import { CustomsDeclaration } from '@xbcb/api-gateway-client';

export const isEuCustomsDeclaration = (
  record?: CustomsDeclaration,
): boolean => {
  if (!record || getRecordType(record.id) !== RecordType.CUSTOMS_DECLARATION) {
    return false;
  }

  const importCountryCode =
    record.goodsShipment?.[0]?.importCountry?.[0]?.country;

  return Boolean(
    importCountryCode &&
      EuCountryCodes[importCountryCode as keyof typeof EuCountryCodes],
  );
};
