import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const BROKER_OVERRIDE_FEATURE = new FeatureFlagService([
  {
    feature: 'BROKER_OVERRIDE_FEATURE',
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { BROKER_OVERRIDE_FEATURE };
