import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserForwarderUserPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserReleaseNotePermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserUserReleaseNoteViewPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.FORWARDER_USER]: ForwarderUserForwarderUserPermissionName,
  [ObjectType.RELEASE_NOTE]: ForwarderUserReleaseNotePermissionName,
  [ObjectType.USER_RELEASE_NOTE_VIEW]:
    ForwarderUserUserReleaseNoteViewPermissionName,
};
